'use client'
import Dropdown from '@/components/core/Dropdown/Dropdown'
import Typography from '@/components/core/Typography'
import ArrowDownFilledIcon from '@/components/icons/ArrowDownFilledIcon'
import {
  AMBASSADORS_URL,
  BENCHMARK_SURVEYS_URL,
  DESTINATION_CONFERENCES_URL,
  EVANTA_URL,
  ONE_MINUTE_INSIGHTS_URL,
  PEER_FINDER_URL,
  RESEARCH_BOARD_URL,
} from '@/components/organisms/NavHeader/constants'
import useProfile from '@/components/providers/profile/useProfile'
import type { DivProps } from '@/types/common'
import PrivateCommunities from '../PrivateCommunities'
import styles from './MenuItems.module.scss'
import MenuItem from './sub/MenuItem'

const MenuItems = (props: DivProps) => {
  const {
    profile: { hasPeerFinderAccess, groupsMembership },
  } = useProfile()

  const shouldRenderConnect =
    hasPeerFinderAccess ||
    (groupsMembership?.length > 0 && process.env['NEXT_PUBLIC_PRIVATE_COMMUNITIES_ENABLED'] === 'true')

  return (
    <div {...props}>
      <Dropdown
        className={styles.dropdown}
        trigger={
          <div className={styles.menu_item}>
            <Typography variant="h6" as="span">
              <b>Resources</b>
            </Typography>
            <ArrowDownFilledIcon size={12} />
          </div>
        }
      >
        <MenuItem name="Benchmark Surveys" href={BENCHMARK_SURVEYS_URL} />
        <MenuItem name="One-Minute Insights" href={ONE_MINUTE_INSIGHTS_URL} />
        <MenuItem name="Ambassador Program" href={AMBASSADORS_URL} target="_blank" />
      </Dropdown>

      {shouldRenderConnect && (
        <Dropdown
          className={styles.dropdown}
          trigger={
            <div className={styles.menu_item}>
              <Typography variant="h6" as="span">
                <b>Connect</b>
              </Typography>
              <ArrowDownFilledIcon size={12} />
            </div>
          }
        >
          {hasPeerFinderAccess && <MenuItem name="Peer Finder" href={PEER_FINDER_URL} className={styles.peer_finder} />}
          {process.env['NEXT_PUBLIC_PRIVATE_COMMUNITIES_ENABLED'] === 'true' && <PrivateCommunities />}
        </Dropdown>
      )}

      <Dropdown
        className={styles.dropdown}
        trigger={
          <div className={styles.menu_item}>
            <Typography variant="h6" as="span">
              <b>Events</b>
            </Typography>
            <ArrowDownFilledIcon size={12} />
          </div>
        }
      >
        <MenuItem name="Evanta" href={EVANTA_URL} target="_blank" />
        <MenuItem name="Destination Conferences" href={DESTINATION_CONFERENCES_URL} target="_blank" />
        <MenuItem name="Research Board" href={RESEARCH_BOARD_URL} target="_blank" />
      </Dropdown>
    </div>
  )
}

export default MenuItems

'use client'
import Typography from '@/components/core/Typography'
import CheckIcon from '@/components/icons/CheckIcon'
import ProfileAnonIcon from '@/components/icons/ProfileAnonIcon'
import VerifiedIcon from '@/components/icons/gpc/VerifiedIcon'
import { Size } from '@/types/common'
import clsx from 'clsx'
import Image from 'next/image'
import { type ComponentPropsWithoutRef, useCallback, useState } from 'react'
import styles from './ImageWithFallback.module.scss'

type ImageWithFallbackCustomProps = {
  profileImage?: string
  alt?: string
  className?: string
  showBadge?: boolean
  size?: Size
  isAmbassador?: boolean
  isVerified?: boolean
  badgeClassName?: string
}

type ImageWithFallbackProps = ImageWithFallbackCustomProps &
  Omit<
    ComponentPropsWithoutRef<typeof Image>,
    'width' | 'height' | 'src' | 'onError' | 'unoptimized' | keyof ImageWithFallbackCustomProps
  >

const ImageWithFallback = ({
  profileImage,
  alt,
  className: classNameProp,
  showBadge,
  size = Size.small,
  isAmbassador = false,
  isVerified = false,
  badgeClassName,
  ...props
}: ImageWithFallbackProps) => {
  const [showFallback, setShowFallback] = useState(!profileImage)
  const onError = useCallback(() => setShowFallback(true), [])
  const className = clsx(
    styles.avatar,
    styles[size],
    isAmbassador && styles.ambassador,
    showBadge && styles.show_badge,
    classNameProp
  )

  const getContent = () => {
    if (!showFallback && !!profileImage) {
      return (
        <Image
          {...props}
          className={styles.avatar_image}
          width={32}
          height={32}
          src={profileImage}
          alt={alt ?? ''}
          onError={onError}
          unoptimized
        />
      )
    }
    if (!!alt && alt.length > 0 && alt !== 'Anonymous User') {
      return (
        <Typography as="span" className={clsx(styles.avatar_fallback)}>
          {alt[0]?.toUpperCase()}
        </Typography>
      )
    }
    return <ProfileAnonIcon className={styles.anonymous_profile_icon} />
  }

  const getBadge = () => {
    if (showBadge) {
      if (isAmbassador) {
        return <CheckIcon className={clsx(styles.badge, badgeClassName)} />
      }
      if (isVerified) {
        return <VerifiedIcon className={clsx(styles.badge, styles.verified_badge, badgeClassName)} />
      }
    }
    return <></>
  }

  return (
    <span className={className}>
      {getContent()}
      {getBadge()}
    </span>
  )
}

export default ImageWithFallback

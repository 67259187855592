type StarIconProps = {
  size?: number
}

const StarIcon = ({ size = 20 }: StarIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      d="M20 7.865L12.81 7.245L10 0.625L7.19 7.255L0 7.865L5.46 12.595L3.82 19.625L10 15.895L16.18 19.625L14.55 12.595L20 7.865ZM10 14.025L6.24 16.295L7.24 12.015L3.92 9.135L8.3 8.755L10 4.725L11.71 8.765L16.09 9.145L12.77 12.025L13.77 16.305L10 14.025Z"
      fill="currentColor"
    />
  </svg>
)

export default StarIcon

import type { IconComponentType } from '../types'

const VerifiedIcon = ({ size = 20, className = '' }: IconComponentType) => (
  <svg width={size} height={size} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="white" />
    <polyline points="7,12 10,15 17,8" stroke="currentColor" strokeWidth="2" fill="none" />
  </svg>
)

export default VerifiedIcon

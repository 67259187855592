type CreateIconProps = {
  size?: number
}

const CreateIcon = ({ size = 24 }: CreateIconProps) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>create icon</title>
    <path
      d="M16 3.05176e-05C7.168 3.05176e-05 0 7.16803 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.16803 24.832 3.05176e-05 16 3.05176e-05ZM24 17.6H17.6V24H14.4V17.6H8V14.4H14.4V8.00003H17.6V14.4H24V17.6Z"
      fill="currentColor"
    />
  </svg>
)

export default CreateIcon

import Typography from '@/components/core/Typography'
import { Modal } from '@/components/organisms/Modal'
import Image from 'next/image'

import Button from '@/components/core/Button'
import { Size } from '@/types/common'
import styles from './ModalAwaitingForApproval.module.scss'
import asset from './assets/awaitingforapprovalgraphic.svg'

type ModalAwaitingForApprovalProps = {
  onCloseHandler: () => void
  keyword?: string
}
const ModalAwaitingForApproval = ({ onCloseHandler, keyword = 'post' }: ModalAwaitingForApprovalProps) => (
  <Modal className={styles.modal_awaiting_for_approval} onClose={onCloseHandler} size={Size.small}>
    <Modal.Header onCloseHandler={onCloseHandler}>
      <Typography as="h3" variant="h3" className={styles.title}>
        Your {keyword} was submitted for approval
      </Typography>
    </Modal.Header>
    <Modal.Body className={styles.modal_body}>
      <div className={styles.content}>
        <div className={styles.modal_text}>
          <div className={styles.text}>
            <Typography variant="paragraph" size="small">
              Our moderators need to verify your profile before publishing your {keyword}. This usually takes about{' '}
              <b>2 business days.</b>
            </Typography>
            <Typography as="h4" variant="paragraph" size="small">
              You{`'`}re automatically following this post so we{`'`}ll notify you after your {keyword} is posted.
            </Typography>
          </div>
          <Image className={styles.image} src={asset} alt="Awaiting approval graphic" width="157" height="121" />
        </div>
        <Button onClick={onCloseHandler}>Keep Browsing</Button>
      </div>
    </Modal.Body>
  </Modal>
)

export default ModalAwaitingForApproval
